import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
// import "./util.scss"
const UtilImage = ({
  image,
  className,
  alt,
  objectFit,
  style,
  onLoad,
  objectPosition,
}) => {
  return (
    <GatsbyImage
      image={image}
      alt={alt}
      className={`${className} w-full`}
      loading="eager"
      objectFit={objectFit}
      style={style}
      onLoad={onLoad}
      objectPosition={objectPosition}
    />
  )
}

export default UtilImage
