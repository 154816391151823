import React from "react";
import { Link, graphql, navigate } from "gatsby";
import Layout from "../components/Layout/Layout";
import Seo from "../components/seo/Seo";
import CartRow from "../components/cart/CartRow";
import { StoreContext } from "../context/store-context";
const Cart = ({ data: { products } }) => {
  const { checkout, removeLineItems } = React.useContext(StoreContext);

  const allProducts = products.nodes;

  const emptyBasket = async () => {
    const lineItems = checkout.lineItems;
    var lineItemIds = [];
    lineItems.map((item) => lineItemIds.push(item.id));
    const checkoutId = checkout.id;
    await removeLineItems(checkoutId, lineItemIds);
    await navigate("/");
  };

  const onClickCheckout = () => {
    const url = checkout.webUrl;
    window.location.href = url;
  };
  return (
    <Layout>
      <Seo title="CART" />
      <div className="mb-8">
        {checkout.lineItems.length > 0 ? (
          <div>
            <div className="flex flex-col leading-none border-b divide-y md:text-lg divide-red border-red">
              {checkout.lineItems.map((item, index) => {
                return (
                  <CartRow
                    key={index}
                    index={index}
                    item={item}
                    variantId={item.variant.id}
                    allProducts={allProducts}
                  />
                );
              })}
            </div>

            <footer className="flex items-center justify-end py-4 text-lg xs:text-lg md:text-4xl lg:text-5xl">
              <div className="flex justify-start w-1/2 space-x-4 text-left md:w-1/2">
                <button
                  className="leading-none text-left uppercase"
                  onClick={() => emptyBasket()}
                >
                  Empty basket
                </button>
                <button
                  onClick={() => onClickCheckout()}
                  className="leading-none uppercase cursor-pointer"
                >
                  Checkout
                </button>
              </div>
            </footer>
          </div>
        ) : (
          <div className="flex flex-col w-full pt-4 text-lg md:text-4xl lg:text-5xl">
            Your cart is empty
            <Link to="/">Start shopping</Link>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Cart;

export const query = graphql`
  {
    products: allSanityShopifyProduct {
      nodes {
        ...ProductCard
      }
    }
  }
`;
