import React from "react";
import { StoreContext } from "../../context/store-context";
const RemoveCart = ({ itemId }) => {
  const { removeLineItem, checkout } = React.useContext(StoreContext);

  const handleRemove = () => {
    removeLineItem(checkout.id, itemId);
  };
  return (
    <button
      onClick={() => handleRemove()}
      className="absolute z-50 top-0 right-0 w-4 md:w-6"
    >
      <svg
        className="w-full h-auto"
        viewBox="0 0 9 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.69999 2.80005H0.899994V11.2H7.69999V2.80005Z"
          stroke="#FF0000"
          strokeWidth="0.614"
        />
        <path d="M0 1.80005H8.5" stroke="#FF0000" strokeWidth="0.614" />
        <path
          d="M4.9 1.30005H1.7L1.5 1.70005H7L6.8 1.30005H4.9Z"
          stroke="#FF0000"
          strokeWidth="0.614"
        />
      </svg>
    </button>
  );
};

export default RemoveCart;
