import React from "react"
import { Base64 } from "js-base64"
import UtilImage from "../../components/util/UtilImage"
import RichText from "../../components/text/RichText"
import RemoveCart from "./RemoveCart"
import { formatPrice } from "../../utils/format-price"
const CartRow = ({ item, index, variantId, allProducts }) => {
  const product = allProducts.find(p =>
    p?.variants[0]?.shopifyVariantID
      ? Base64.decode(p?.variants[0]?.shopifyVariantID) === variantId ||
        p?.variants[0]?.shopifyVariantID === variantId
      : undefined
  )
  console.log(item, variantId)
  const price = formatPrice("GBP", product?.maxVariantPrice)

  return (
    <div className="relative flex flex-wrap w-full py-6 first:pt-0 md:first:pt-0 md:flex-no-wrap md:py-12">
      {/* <Link to={product.slug} className="absolute inset-0 z-10 block"></Link> */}
      <div className="flex w-1/2">
        <header className="w-full p-4 pt-0 pl-0 leading-none md:pt-4 md:w-1/12">
          Book #{index + 1}
        </header>
        <figure className="relative w-1/2 pr-12 md:p-4 md:pl-8">
          {product?.cover && (
            <UtilImage
              image={product.cover.cover.asset.gatsbyImageData}
              alt={product.title}
              className="object-contain object-top w-full h-full cover"
              position="relative"
              objectFit="contain"
              objectPosition="top"
            />
          )}
        </figure>
      </div>
      <div className="flex items-start justify-between flex-1 w-1/2 md:py-4">
        <div className="relative flex flex-col w-full min-h-full leading-none md:pr-12">
          <h3 className="pr-12">{product?.title}</h3>
          <RemoveCart itemId={item.id}></RemoveCart>
          <div className="description">
            {product?.shortDescription &&
              product.shortDescription.map(child => {
                return (
                  <RichText
                    key={child._key}
                    className="mt-5 normal-case"
                    blocks={child}
                  />
                )
              })}
          </div>
          <div className="flex justify-between mt-auto">
            <div className="md:-mr-12">{price}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CartRow
